import translations from './translations.json';

// Función para cambiar el idioma
function changeLanguage(lang) {
    document.documentElement.lang = lang;
    const elements = document.querySelectorAll('[data-lang]');
    elements.forEach(element => {
        const key = element.getAttribute('data-lang');
        if (translations[lang] && translations[lang][key]) {
            element.textContent = translations[lang][key];
        }
    });
    
    const langLinks = document.querySelectorAll('#navLang a,#navLangMov a');
    langLinks.forEach(langLinks => {
        var userLanguageConcat = langLinks.getAttribute('id');
        var partes = userLanguageConcat.split('-'); // Dividir la cadena en un array usando '-' como separador
        if (lang == partes[0])
        {
            langLinks.style.color = "rgb(0, 159, 152)";
        } 
        else
        {
            langLinks.style.color = "inherit";
        }
    });

    cambiarHrefsDeDownloadButtons();
    localStorage.setItem("idioma", lang);
}

// Manejar clics en los enlaces de idioma
const langLinks = document.querySelectorAll('#navLang a,#navLangMov a');
langLinks.forEach(link => {
    link.addEventListener('click', (event) => {
        event.preventDefault();
        var userLanguageConcat = link.getAttribute('id');
        var partes = userLanguageConcat.split('-'); // Dividir la cadena en un array usando '-' como separador
        userLanguage = partes[0]; // Acceder al primer elemento del array
        changeLanguage(userLanguage);
    });
});


// Cambiar el idioma inicial
// var userLanguage = navigator.language.split('-')[0];
var userLanguage = localStorage.getItem("idioma");

// Comprueba si hay un idioma almacenado y configura el idioma en consecuencia
if (userLanguage) {
  // Configura el idioma en tu página según el valor recuperado
  // Por ejemplo, puedes cargar archivos JSON con traducciones específicas según el idioma.
}else{
    // Obtiene el idioma preferido del navegador del usuario
    var userLanguageNav = navigator.language || navigator.userLanguage;

    // Comprueba el idioma y realiza acciones según sea necesario
    if (userLanguageNav.startsWith('es')) {
        userLanguage = 'es';
    } else if (userLanguageNav.startsWith('ca')) {
        userLanguage = 'ca';
    } else {
        userLanguage = 'en';
        // El idioma no es español ni inglés, puedes manejar otros idiomas aquí
    }
}

if (translations[userLanguage]) {
    changeLanguage(userLanguage);
} else {
    changeLanguage('en'); // Idioma predeterminado
}


//Animación inicio
const textElement = document.getElementById("typewriterTextJS");
let textIndex = 0;
let charIndex = 0;
let isDeleting = false;

function type() {

    if (textElement !== null)
    {
        const currentText = translations[userLanguage]["TextosInicio"][textIndex]; 
    
        if (!isDeleting) {
            textElement.innerHTML = currentText.substring(0, charIndex);
            charIndex++;
        } else {
            textElement.innerHTML = currentText.substring(0, charIndex);
            charIndex--;
        }
    
        if (charIndex > currentText.length && !isDeleting) {
            isDeleting = true;
            setTimeout(type, 1000); // Pausa de 1 segundo después de escribir
        } else if (charIndex === 0) {
            isDeleting = false;
            textIndex = (textIndex + 1) % translations[userLanguage]["TextosInicio"].length;
            setTimeout(type, 20); // Pausa de 0.5 segundos antes de escribir
        } else {
            const speed = isDeleting ? 20 : 90; // Velocidades de escritura y borrado
            setTimeout(type, speed);
        }
    }

}

type();
//Animación menu
const menu = document.querySelector(".MenuSuperior");

window.addEventListener("scroll", () => {
    if (window.scrollY > 50) { // Cambia 50 a la cantidad de desplazamiento que desees
        menu.classList.add("scrolled");
    } else {
        menu.classList.remove("scrolled");
    }
});

//Animación aparecer
const contents = document.querySelectorAll(".animacionAparecer");
const windowHeight = window.innerHeight;

function checkVisibility() {
    const midWindow = windowHeight / 1.4;
    contents.forEach(content => {
        const contentOffset = content.offsetTop;
        const scrollY = window.scrollY;

        if (scrollY + midWindow > contentOffset) {
            content.classList.remove("aparecerHidden");
            content.classList.add("aparecerVisible");
        }
    });
}

window.addEventListener("scroll", checkVisibility);
checkVisibility();

// JavaScript para mostrar/ocultar el menú desplegable al pasar el ratón
const dropdownContainer = document.querySelector('.dropdown');
const dropdownContent = document.querySelector('.dropdown-content');
let timeoutId; // Variable para almacenar el ID del temporizador

dropdownContainer.addEventListener('mouseenter', function() {
  // Mostrar el menú inmediatamente al pasar el ratón por encima del botón "Servicios"
  if (window.innerWidth > 768) {
    dropdownContent.style.display = 'block';
    dropdownContent.style.opacity = '1';
    clearTimeout(timeoutId);
  }

});

dropdownContainer.addEventListener('mouseleave', function() {
  // Configurar un retraso de 500 ms (medio segundo) antes de ocultar el menú
  timeoutId = setTimeout(function() {
    dropdownContent.style.opacity = '0'; // Establecer la opacidad en 0 para desvanecer
  }, 500);
});

dropdownContent.addEventListener('mouseenter', function() {
  // Cancelar el retraso si el cursor entra en el contenido del menú
  clearTimeout(timeoutId);
});

dropdownContent.addEventListener('mouseleave', function() {
  // Ocultar el menú si el cursor sale del contenido del menú
   timeoutId = setTimeout(function() {
    dropdownContent.style.opacity = '0'; 
  }, 500);
});


// Función para mostrar el contenido de la pestaña seleccionada
function showTab(tabId) {
    // Oculta todos los contenidos de pestañas
    var tabContents = document.querySelectorAll('.tab-content');
    tabContents.forEach(function(content) {
        content.classList.remove('show');
    });

    // Muestra el contenido de la pestaña seleccionada
    var tabContent = document.getElementById(tabId);
    if (tabContent !== null){
        tabContent.classList.add('show');
    }
    

    // Cambia el estado activo del botón de la pestaña
    var tabButtons = document.querySelectorAll('.tab-button');
    tabButtons.forEach(function(button) {
        if (button.getAttribute('data-tab') === tabId) {
            button.classList.add('active');
        } else {
            button.classList.remove('active');
        }
    });
}

// Agrega un evento de clic a los botones de pestaña
var tabButtons = document.querySelectorAll('.tab-button');
tabButtons.forEach(function(button) {
    button.addEventListener('click', function() {
        var tabId = this.getAttribute('data-tab');
        showTab(tabId);
    });
});

// Mostrar la pestaña "Descripción" por defecto al cargar la página
showTab('descripcion');


// // Función para descargar el archivo
function cambiarHrefsDeDownloadButtons() {
    // Obtén todos los elementos <a> con la clase "downloadButton"
    var downloadButtons = document.querySelectorAll('.contenedorBTNDescarga');
  
    // Itera sobre cada elemento <a> y cambia su atributo "href"
    downloadButtons.forEach(function(button) {
        var lanAtt = button.getAttribute('data-lan-active');
        if (lanAtt != userLanguage)
        {
            button.style.display = "none"
        }
        else
        {
            button.style.display = "block"
        }
    });
  }
  
  function onPageLoad() {
    cambiarHrefsDeDownloadButtons();
  }
  // Registra la función "onPageLoad" para ejecutarse cuando se carga la página
  window.addEventListener('load', onPageLoad);


// Función para mostrar u ocultar el menú desplegable y cambiar el icono
document.getElementById("mobileMenuBtn").addEventListener("click", function () {
    var menu = document.getElementById("divNav");
    var Submenu = document.getElementById("navContent");
    var icon = document.getElementById("mobileMenuBtn");
    if (menu.style.display === "block") {
        menu.style.display = "none";
        Submenu.style.display = "none";
        icon.innerHTML = "&#9776;"; // Cambia de nuevo a la hamburguesa
        icon.color= "color: var(--color-secundario)"
    } else {
        menu.style.display = "block";
        Submenu.style.display = "block";
        icon.innerHTML = '&#10006;'; // Cambia a la cruz
        icon.color= "color: var(--color-secundario)"
    }
});

// Añade un evento para ocultar el menú cuando se hace clic en cualquier enlace del menú
var links = document.querySelectorAll("#navContent a");
links.forEach(function (link) {
    link.addEventListener("click", function () {
        document.getElementById("navContent").style.display = "none";
        document.getElementById("mobileMenuBtn").innerHTML = "&#9776;"; // Cambia de nuevo a la hamburguesa
    });
});

document.addEventListener("DOMContentLoaded", function () {
    const cookieNotice = document.getElementById("cookie-notice");
    const acceptCookie = document.getElementById("accept-cookie");
    const rejectCookie = document.getElementById("reject-cookie");

    // Verificar si el usuario ha aceptado las cookies previamente
    const cookiesAccepted = localStorage.getItem("cookies-accepted");

    if (!cookiesAccepted) {
        cookieNotice.style.display = "block";
    }

    // Manejar el evento de aceptar cookies
    acceptCookie.addEventListener("click", function () {
        cookieNotice.style.display = "none";
        // Almacenar la información de que el usuario ha aceptado las cookies
        localStorage.setItem("cookies-accepted", "true");
    });

    // Manejar el evento de rechazar cookies
    rejectCookie.addEventListener("click", function () {
        cookieNotice.style.display = "none";
        // Almacenar la información de que el usuario ha aceptado las cookies
        localStorage.setItem("cookies-accepted", "false");
    });
});


