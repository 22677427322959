{
    "es": {
        "TextosInicio":["Innovando para el futuro", "Desarrollo personalizado", "Aplicaciones IoT", "Adaptación para la industria 4.0", "Optimización de procesos", 
        "Optimización de recursos", "Reducción de errores"],
        "Menu-INICIO": "INICIO",
        "Menu-EMPRESA": "EMPRESA",
        "Menu-PRODUCTOS": "PRODUCTOS",
        "Menu-CONTACTO": "CONTACTO",
        "Menu-BLOG": "BLOG",
        "MenuFoot-INICIO": "Inicio",
        "MenuFoot-EMPRESA": "Empresa",
        "MenuFoot-PRODUCTOS": "Productos",
        "MenuFoot-CONTACTO": "Contacto",
        "MenuFoot-BLOG": "Blog",
        "MenuFoot-PoliticaCookies":"Política de cookies",
        "MenuFoot-Derechos":"© 2022 CTNEAT. Todos los derechos reservados. Diseño de Duplex Creativity creación de CTNEAT.",
        "Ind40":"Industria 4.0", 
        "Ind40_1":"Desarrollo, gestión e implementación de aplicaciones hiperconectadas y sistemas físicos cibernéticos (CPS) aplicados a la industria.",
        "Ind40_2":"Optimización de los niveles de calidad, reducción de tiempos de producción y reducción de costes.",
        "IoT":"IoT",
        "IoT_1":"Aplicación de las tecnologías IoT a las necesidades de la industria. Adquisición automática de datos para la optimización de los procesos, creación de registros y validación continua.",
        "DesPers":"Desarrollo personalizado",
        "DesPers_1":"Estudio de las necesidades del cliente para el desarrollo e implementación de aplicaciones personalizadas para todo tipo de dispositivos.",
        "BigData":"Big Data",
        "BigData_1":"Creación y gestión de bases de datos de cualquier tamaño y complejidad. Creación de modelos a medida para un seguimiento y control óptimos y eficientes.",
        "h1CTSten": "Solución de almacenamiento para pantallas de serigrafía SMD",
        "h2CTSten": "CTSten | Trazabilidad, agilidad y control de envejecimiento",
        "CTSTEN_Desc_1":"Armario inteligente para almacenar pantallas de serigrafía SMD con marco autotensable. Tiene una capacidad para 90 pantallas con la posibilidad de ampliarla con 5 módulos de 90 pantallas cada uno y control de hasta 50 pantallas externas.",
        "CTSTEN_Desc_2":"Doble LED RGB, arriba y abajo para un centrado perfecto de la pantalla, el color es programable para indicar la vida útil de la pantalla.",
        "CTSTEN_Desc_3":"Generación de histórico de eventos y usuarios. Mediante este control se puede saber las pantallas con más tiempo sin uso y su vida útil.",
        "CTSTEN_Desc_4":"Visualización de las pantallas antiguas para optimizar espacio, verificación espacios vacíos o llenos.",
        "CTSTEN_Desc_5":"Los modelos actuales son: CTsten2323M, CTsten2329M, Ctsten2929M y CTsten2929MF, este último con marco fijo y solo 18 pantallas.",
        "PODUCTOS_Carac_1":"Periféricos",
        "PODUCTOS_Carac_2":"Software",
        "CTSTEN_Carac_2":"Pantalla táctil de 7”.",
        "CTSTEN_Carac_3":"Copia de seguridad local sobre “pen drive”.",
        "CTSTEN_Carac_4":"Lector de códigos de barras, datamatrix, QR, para identificar las pantallas.",
        "CTSTEN_Carac_5":"Incluye teclado inalámbrico con “touch pad” para facilitar la entrada de datos.",
        "CTSTEN_Carac_6":"Apoyo por lápiz y teclado.",
        "CTSTEN_Carac_7":"Conexión WIFI y Ethernet para comunicarse con la ERP.",
        "CTSTEN_Carac_8":"Capacidad de extraer una pantalla a partir de la información de una fabricación enviada por red.",
        "CTSTEN_Carac_9":"Las pantallas se pueden dar de alta en el mismo armario o enviarle un fichero por red.",
        "CTSTEN_Carac_10":"Asignación automática o manual del lugar de la pantalla.",
        "CTSTEN_Carac_11":"El software personalizable para añadir funciones específicas.",
        "CTSTEN_Tec_1":"Cuatro modelos:",
        "CTSTEN_Tec_2":"Para 90 pantallas con LCD 7”",
        "CTSTEN_Tec_3":"Para 18 pantallas con marco rígido",
        "CTSTEN_Tec_4":"COTA",
        "CTSTEN_Tec_5":"VALOR",
        "CTSTEN_Picto_1":"Indicación luminosa de posición y vida útil",
        "CTSTEN_Picto_2":"No pierdas el tiempo buscando pantalles",
        "CTSTEN_Picto_3":"Histórico de pantallas",
        "CTSTEN_Picto_4":"Hasta 6 almacenes de diferentes tamaños con un solo control",
        "CTSTEN_Picto_5":"Conectividad con tu ERP",
        "CTSTEN_MiniDesc_1":"Armario inteligente de hasta 90 pantallas conectable al ERP. Permite identificar, guardar y extraer pantallas de una forma más eficiente y segura. Control de trazabilidad y envejecimiento de las pantallas.",
        "h1Prod": "Software de control de producción",
        "h2Prod": "CTProd | Controla cada paso en tu cadena de producción",
        "CTPROD_MiniDesc_1":"Sistema de control de producción y seguimiento de las fases de desarrollo del producto. Permite tener una trazabilidad total llegando hasta nivel de componente y una calidad garantizada en la cadena de producción.",
        "CTPROD_Desc_1":"CTPROD es un ERP que permite un control sencillo de los procesos de producción y operarios, además, es un sistema modulable y adaptable a las necesidades de cada cliente.",
        "CTPROD_Desc_2":"Creación de producto final con control por fase, tiempo, operarios y costes.",
        "CTPROD_Desc_3":"Información a tiempo real del estado de las ordenes de fabricación. Tiempo empleado, restante y unidades hechas en cada fase de la orden de trabajo.",
        "CTPROD_Desc_4":"Trazabilidad para enlazar la producción con la materia prima.",
        "CTPROD_Desc_5":"Test de validación para el control de calidad.",
        "CTPROD_Desc_6":"Control de la jornada laboral de los empleados para cumplir con la normativa laboral.",
        "CTPROD_Desc_7":"La interacción con los operarios mediante módulos táctiles repartidos en cada estación de trabajo.",
        "CTPROD_Cararc_1":"Pantalla táctil de 7”.",
        "CTPROD_Cararc_2":"Lector de código de barras.",
        "CTPROD_Cararc_3":"Módulos con monitores de 24”. (Opcional)",
        "CTPROD_Cararc_4":"Software modulable y adaptable.",
        "CTPROD_Cararc_5":"Conexión WIFI y Ethernet para comunicarse con la ERP.",
        "CTPROD_Cararc_6":"Trazabilidad de la producción. Tiempo, materiales y costes.",
        "CTPROD_Cararc_7":"Control del estado de las fabricaciones.",
        "CTPROD_Cararc_8":"Control de la jornada de los operarios.",
        "CTPROD_Cararc_9":"Control de calidad.",
        "CTPROD_Cararc_10":"El software personalizable para añadir funciones específicas.",
        "CTPROD_Picto_1":"Indicación luminosa de posición y vida útil",
        "CTPROD_Picto_2":"No pierdas el tiempo buscando pantalles",
        "CTPROD_Picto_3":"Histórico de pantallas",
        "CTPROD_Picto_4":"Hasta 6 almacenes de diferentes tamaños con un solo control",
        "CTPROD_Picto_5":"Conectividad con tu ERP",
        "h1Incoming":"Estación de recepción de material",
        "h2Incoming": "CTIncoming | Evita errores y genera trazabilidad",
        "CTIncoming_MiniDesc_1":"Estación automatizada de entrada de materiales. Optimiza el proceso de recepción de materiales mediante visión artificial.",
        "CTIncoming_Desc_1":"CTIncoming es una mesa ergonómica de entrada de material equipada con una cámara de alta resolución de 20 Megapíxel y un PC en el cual se ejecuta el software de gestión de recepciones.",
        "CTIncoming_Desc_2":"El material entrante se escanea y los datos se cotejan automáticamente con la base de datos del ERP de la empresa. La estación crea una identificación única en el sistema para cada material y hace que cada componente sea único para facilitar una trazabilidad completa.",
        "CTIncoming_Desc_3":"El sistema reconoce todos los códigos de barras (1D y 2D) gracias a una cámara de alta resolución. Permite generar reglas de reconocimiento para la clasificación de los códigos de barras leídos y guardar los valores junto al código único creado.",
        "CTIncoming_Desc_4":"Admite la conexión a sistemas de terceros como ERPs y armarios mediante la API integrada.",
        "CTIncoming_Desc_5":"Dispone de una aplicación móvil para poder recepcionar paquetes a distancia.  Esta extensión está especialmente diseñada para aquellos paquetes voluminosos o pesados que no se puedan subir al escáner.",
        "CTIncoming_Carac_1":"PC i7, 8GB RAM 256 GB.",
        "CTIncoming_Carac_2":"Monitor táctil de FullHD 23.8” y soporte brazo articulado.",
        "CTIncoming_Carac_3":"Teclado y ratón.",
        "CTIncoming_Carac_4":"Mesa ergonómica (1500 x 750 x 670-1100)",
        "CTIncoming_Carac_5":"Cámara 20 MP y lente.",
        "CTIncoming_Carac_6":"Impresora de etiquetas.",
        "CTIncoming_Carac_7":"Lectura de códigos 1D y 2D.",
        "CTIncoming_Carac_8":"Reconocimiento y clasificación de texto leído a los códigos.",
        "CTIncoming_Carac_9":"Entrada de material a la base de datos y al ERP.",
        "CTIncoming_Carac_10":"Comunicación con ERPs y armarios inteligentes.",
        "CTIncoming_Carac_11":"Comunicació amb ERPs i armaris intel·ligents.",
        "CTIncoming_Picto_1":"Recepción de materiales sin errores",
        "CTIncoming_Picto_2":"Reducción del tiempo de recepción",
        "CTIncoming_Picto_3":"Trazabilidad total del material por UID",
        "CTIncoming_Picto_4":"Conectividad con armarios inteligentes",
        "CTIncoming_Picto_5":"Conectividad con el ERP",
        "CTIncoming_Picto_6":"Extensión móvil",
        "PRODUC_DT_1":"ANCHO",
        "PRODUC_DT_2":"PROFUNDIDAD",
        "PRODUC_DT_3":"ALTURA",
        "h1Shelf":"Almacénm automático SMD",
        "h2Shelf":"CTShelf | Almacenamiento y recuperación eficientes",
        "CTShelf_Picto_1":"Indicadores lumínicos y sonoros",
        "CTShelf_Picto_2":"Sensores de posición",
        "CTShelf_Picto_3":"Integración con CTShelf Manager",
        "CTShelf_Picto_4":"Libre costumización",
        "CTShelf_Picto_5":"Orden, Rapidez y sencillez",
        "CTShelf_MiniDesc_1":"Armario de Almacenamiento de Bobinas SMD con la capacidad de almacenar hasta 1200 bobinas.",
        "CTShelf_Desc_1":"CTShelf es armario caótico de Bobinas SMD, una solución altamente adaptable para la gestión eficiente de componentes electrónicos en entornos industriales.",
        "CTShelf_Desc_2":"Este producto puede ser configurado de manera flexible para acomodar toda la gama de tamaños de bobinas SMD, lo que brinda una personalización completa según las necesidades del usuario. Con una capacidad de almacenamiento de hasta 1200 bobinas SMD, este armario es una opción ideal para entornos de producción de alto volumen.",
        "CTShelf_Desc_3":"Cada posición de almacenamiento está equipada con un indicador luminoso que utiliza tecnología LED RGB y un sensor de posición para proporcionar una identificación visual clara del estado de las bobinas.",
        "CTShelf_Desc_4":"Además, CTShelf incluye un indicador sonoro para brindar información adicional sobre el estado de las bobinas.",
        "CTShelf_Desc_5":"Dispone de conexión con el software CTShelf Manager para el control de la inserción y extracción de componentes de forma más precisa y rápida.",
        "CTShelf_Carac_1":"CTShelf Manager (hiperviculado con la pagina de ctshelf manager de la web).",
        "CTShelfManag_MiniDesc_1":"Software de gestión de producción y de almacén diseñado para mejorar la eficiencia y maximizar el control sobre los recursos y procesos.  Cuenta con soporte para Windows y dispositivos Android.",
        "CTShelfManag_Desc_1":"CTShelf Manager es un software de gestión de producción que ha sido diseñado con el propósito de incrementar la eficiencia y proporcionar un mayor control sobre los recursos y procesos involucrados en la producción. Este versátil software es compatible tanto con sistemas Windows como con dispositivos Android.",
        "CTShelfManag_Desc_2":"El software ofrece una amplia funcionalidad en la gestión de órdenes de trabajo, permitiendo a los usuarios diseñar, gestionar y hacer seguimiento de las órdenes de producción de manera eficaz. Esto incluye la asignación de recursos, el establecimiento de plazos y el mantenimiento de un control preciso en todo el proceso. Además, el software facilita el inicio y cierre de las órdenes de trabajo, brindando la flexibilidad necesaria para realizar ajustes en tiempo real y adaptarse a las cambiantes necesidades de producción.",
        "CTShelfManag_Desc_3":"La gestión de stock es otra área en la que CTShelf Manager brilla. Proporciona un seguimiento detallado de los materiales, lo que ayuda a mantener un equilibrio adecuado en los niveles de inventario. CTShelf Manager también ofrece herramientas para la generación de informes para tener un resumen de las ordenes de trabajo junto a los contenedores que han formado parte de esta.",
        "CTShelfManag_Desc_4":"Además de su versatilidad en Windows, CTShelf Manager cuenta con una aplicación Android que simplifica aún más su uso en la gestión del almacén. Esta extensión móvil permite a los usuarios acceder al software desde dispositivos Android, brindando flexibilidad en la gestión de almacenes.",
        "CTShelfManag_Desc_5":"Para una mayor integración y eficiencia, el software se puede sincronizar con sistemas ERP, evitando duplicaciones de datos y garantizando una comunicación fluida entre diferentes partes del negocio.",
        "CTShelfManag_Desc_6":"Por último, CTShelf Manager ofrece la opción de integrarse con los almacenes inteligentes CTShelf y CTShelf mini, lo que complementa y mejora aún más la gestión del almacén al aprovechar la tecnología avanzada de estos sistemas.",
        "CTShelfManag_Carac_1":"Comunicación con ERPs.",
        "CTShelfManag_Carac_2":"Comunicación y gestión de armaros externos y extensiones CTShelf y CTShelf Mini.",
        "CTShelfManag_Carac_3":"Comunicación con CTIncoming.",
        "CTShelfManag_Carac_4":"Trazabilidad de ordenes de trabajo y almacén.",
        "CTShelfManag_Carac_5":"Creación, planificación, control y manupulación de ordenes de trabajo.",
        "CTShelfManag_Carac_6":"Aplicación Android.",
        "CTShelfManag_Carac_7":"Generación de reportes.",
        "CTShelfManag_Picto_1":"Gestión y control de la producción y almacenes",
        "CTShelfManag_Picto_2":"Extensión móvil",
        "CTShelfManag_Picto_3":"Generación de reportes",
        "CTShelfManag_Picto_4":"Conectividad con armarios inteligentes",
        "CTShelfManag_Picto_5":"Conectividad con el ERP",
        "h1ShelfManager":"MRP y software de gestión de almacén",
        "h2ShelfManager":"CTShelf Manager | Gestión eficiente de la producción y el almacén.",
        "PRODUC_TABS_1":"Descripción",
        "PRODUC_TABS_2":"Características",
        "PRODUC_TABS_3":"Datos Técnicos",
        "PRODUC_TABS_4":"Descargas",
        "PRODUC_Descarga_1":"Folleto",
        "CONTACTO_1":"HABLAMOS",
        "CONTACTO_2":"¿Quieres información sobre alguno de nuestros productos? ¿Tienes algún proyecto que quieras desarrollar con nosotros? ¿Quieres adaptarte a la industria 4.0? Habla con nosotros.",
        "CONTACTO_3":"NUESTROS DATOS",
        "CONTACTO_4":"CONTACTO",
        "CONTACTO_5":"Nombre",
        "CONTACTO_6":"Empresa",
        "CONTACTO_7":"Teléfono",
        "CONTACTO_8":"Mensaje",
        "CONTACTO_9":"Acepto el aviso legal y la política de privacidad",
        "CONTACTO_10":"Enviar",
        "PRODUCTOS_1":"La tecnología al servicio del usuario",
        "PRODUCTOS_2":"En CTNEAT fabricamos equipos y aplicaciones de software que permiten mejorar los tiempos de producción, comunicar sistemas con nuevas tecnologías IoT y controlar la producción y trazabilidad.",
        "PRODUCTOS_3":"Armario inteligente de hasta 90 pantallas conectable al ERP.",
        "PRODUCTOS_4":"Sistema de control de producción y seguimiento de las fases de desarrollo del producto.",
        "PRODUCTOS_5":"Estación de entrada de materiales",
        "PRODUCTOS_6":"Armario de Almacenamiento de Bobinas SMD",
        "PRODUCTOS_7":"Software de gestión de producción y de almacén",
        "PRODUCTOS_8":"Más Info",
        "Empresa_1":"EMPRESA",
        "Empresa_2":"CTneat somos una ingeniería electrónica con mes de 30 años de experiencia enfocada al desarrollo de soluciones por la industria 4.0.",
        "Empresa_3":"Nuestros productos están pensados para adaptarse a las necesidades generales de la industria, con la capacidad de ser personalizados por cada cliente, tanto a nivel de software como de hardware.",
        "Empresa_4":"Trabajamos estrechamente con colaboradores que nos facilitan la fabricación de los productos diseñados por nuestros ingenieros y entregarlos totalmente verificados.",
        "Empresa_5":"Desde el año 2001 estamos ubicados en Sentmenat con una buena comunicación con Barcelona.",
        "Empresa_6":"Crecimiento",
        "Empresa_7":"Nuestro carácter emprendedor nos ha permitido lograr una expansión que sigue creciendo día a día, apostando miedo las nuevas tecnologías y el talento joven.",
        "Empresa_8":"Comunidad",
        "Empresa_9":"Nuestra filosofía se basa en el desarrollo de soluciones Internacionales, pero manteniendo la esencia que nos ha hecho crecer. Una esencia marcada por la apuesta en el mercado local.",
        "Empresa_10":"Compromiso",
        "Empresa_11":"Motivados por nuestro empeño de mejora continua de productos y procesos, hemos creado un compromiso con la optimización de los recursos medioambientales.",
        "Empresa_12":"¿Qué hacemos?",
        "Empresa_13":"Desarrollamos proyectos de innovación industrial con varias tecnologías:",
        "Empresa_14":"Aplicaciones IoT, BLE y WiFi.",
        "Empresa_15":"Visión artificial.",
        "Empresa_16":"Conectividad.",
        "Empresa_17":"Fabricamos equipos y aplicaciones de software que permiten:",
        "Empresa_18":"Mejorar los tiempos de producción.",
        "Empresa_19":"Comunicar sistemas con nuevas tecnologías IoT.",
        "Empresa_20":"Control de la producción y trazabilidad.",
        "BLOG_1":"ACTUALIDAD",
        "titulo-politica-cookies": "POLÍTICA DE COOKIES",
        "seccion-1-titulo": "1. QUÈ SÓN LES COOKIES ?",
        "seccion-1-parrafo-1": "Las cookies son pequeños archivos de datos que se reciben en el terminal desde el sitio web visitado y se utilizan para registrar ciertas interacciones de la navegación en un sitio web almacenando datos que pueden ser actualizados y recuperados. Estos archivos se almacenan en la computadora del usuario y contienen datos anónimos que no son perjudiciales para su equipo. Se utilizan para recordar las preferencias del usuario, como el idioma seleccionado, datos de acceso o personalización de la página.",
        "seccion-1-parrafo-2": "Las cookies también pueden ser utilizadas para registrar información anónima sobre cómo un visitante utiliza un sitio web. Por ejemplo, desde qué página ha accedido o si ha utilizado un banner publicitario para llegar allí.",
        "seccion-2-titulo": "2. PER QUÈ UTILITZEM COOKIES ?",
        "seccion-2-parrafo-1": "CTNEAT utiliza cookies estrictamente necesarias y esenciales para que los usuarios utilicen nuestros sitios web y les permitan moverse libremente, utilizar áreas seguras, opciones personalizadas, etc. Además, CTNEAT utiliza cookies que recopilan datos relacionados con el análisis de uso del sitio web. Estos se utilizan para ayudar a mejorar el servicio al cliente, medir el uso y el rendimiento de la página, optimizarla y personalizarla.",
        "seccion-2-parrafo-2": "Nuestros sitios web también pueden tener enlaces de redes sociales (como Facebook o Twitter). CTNEAT no controla las cookies utilizadas por estos sitios web externos. Para obtener más información sobre las cookies de las redes sociales u otros sitios web externos, recomendamos revisar sus propias políticas de cookies.",
        "seccion-2-parrafo-3": "Este sitio web utiliza 'cookies' de la siguiente manera:",
        "seccion-2-lista-item-1": "Cookie de Google ANALYTICS: Google ANALYTICS es un servicio gratuito ofrecido por Google Inc. que recopila información sobre las páginas del sitio web visitado, a qué hora, qué navegador web, etc. Posteriormente, esta información se envía a los servidores de Google Inc. en los Estados Unidos.",
        "seccion-2-lista-item-2": "Cookies de aplicación 'Add-this': Add-this es un servicio gratuito que le permite compartir páginas de este sitio web a través de diferentes medios (correo electrónico, redes sociales, etc.). Add-this recopila información estadística de su navegación para proporcionar publicidad adaptada a sus hábitos de navegación en este sitio web o en cualquier otro lugar.",
        "seccion-3-titulo1": "Según su finalidad:",
        "seccion-3-titulo2": "Cookies técnicas: Las cookies técnicas son imprescindibles y estrictamente necesarias para el correcto funcionamiento de un portal web y la utilización de las diferentes opciones y servicios que ofrece. Por ejemplo, las que sirven para el mantenimiento de la sesión, la gestión del tiempo de respuesta, rendimiento o validación de opciones, utilizar elementos de seguridad, compartir contenido con redes sociales, etc.",
        "seccion-3-titulo3": "Cookies de personalización: Estas cookies permiten al usuario especificar o personalizar algunas características de las opciones generales del sitio web, como definir el idioma, configuración regional o tipo de navegador.",
        "seccion-3-titulo4": "Cookies analíticas: Las cookies analíticas son las utilizadas por nuestro portal web para elaborar perfiles de navegación y conocer las preferencias de los usuarios del mismo para mejorar la oferta de productos y servicios. Por ejemplo, a través de una galleta analítica se controlarían las áreas geográficas de mayor interés de un usuario, cuál es el producto de mayor aceptación, etc.",
        "seccion-3-titulo5": "Cookies publicitarias / de publicidad: Las cookies publicitarias permiten la gestión de los espacios publicitarios en base a criterios concretos. Por ejemplo la frecuencia de acceso, el contenido editado, etc. Las cookies de publicidad permiten a través de la gestión de la publicidad almacenar información del comportamiento a través de la observación de hábitos, estudiando los accesos y formando un perfil de preferencias del usuario para ofrecer publicidad relacionada con los intereses de su perfil.",
        "seccion-3-titulo6": "Según su plazo:",
        "seccion-3-titulo7": "Cookies de sesión: Las cookies de sesión son aquellas que duran el tiempo que el usuario está navegando por la página web y se borran al término.",
        "seccion-3-titulo8": "Cookies persistentes: Estas cookies quedan almacenadas en el terminal del usuario, por un tiempo más largo, facilitando así el control de las preferencias elegidas sin tener que repetir ciertos parámetros cada vez que se visite el sitio web.",
        "seccion-3-parrafo-1": "Estas cookies deben cumplir con el RGPD-UE-2016/679, la LOPD-3/2018, de Garantía de los Derechos Digitales, y la normativa de la LSSI-CE.",
        "seccion-4-titulo1": "COOKIES PROPIAS EN USO:",
        "seccion-4-titulo2": "Se han permitido las siguientes cookies al visitar esta página, entre otras:",
        "unique1": "DOMINIO",
        "unique2": "COOKIE",
        "unique3": "EMPRESA",
        "unique4": "FUNCIÓN",
        "unique5": "CADUCIDAD",
        "unique6": "www.ctneat.com",
        "unique7": "_utma",
        "unique8": "Google Analytics",
        "unique9": "Analítica",
        "unique10": "2 años",
        "unique11": "_utmb",
        "unique12": "Analítica",
        "unique13": "30 minutos",
        "unique14": "_utmc",
        "unique15": "Analítica",
        "unique16": "Sesión",
        "unique17": "_utmt",
        "unique18": "Analítica",
        "unique19": "10 minutos",
        "unique20": "_utmv",
        "unique21": "Analítica",
        "unique22": "Sesión",
        "unique23": "_utmz",
        "unique24": "Analítica",
        "unique25": "6 meses",
        "unique26": "COOKIES EXTERNAS EN USO",
        "unique27": "Algunas de las cookies en uso",
        "unique28": "DOMINIO",
        "unique29": "COOKIE",
        "unique30": "EMPRESA",
        "unique31": "FUNCIÓN",
        "unique32": "CADUCIDAD",
        "unique33": "www.ctneat.com",
        "unique34": "cookielawinfo-checkbox-performance",
        "unique35": "GDPR Cookie Consent plugin",
        "unique36": "Performance",
        "unique37": "11 meses",
        "unique38": "www.ctneat.com",
        "unique39": "cookielawinfo-checkbox-analytics",
        "unique40": "GDPR Cookie Consent plugin",
        "unique41": "Analytics",
        "unique42": "11 meses",
        "unique43": "www.ctneat.com",
        "unique44": "cookielawinfo-checkbox-others",
        "unique45": "GDPR Cookie Consent plugin",
        "unique46": "Other",
        "unique47": "11 meses",
        "unique48": "www.ctneat.com",
        "unique49": "viewed_cookie_policy",
        "unique50": "GDPR Cookie Consent plugin",
        "unique51": "&nbsp;",
        "unique52": "11 meses",
        "unique53": "www.ctneat.com",
        "unique54": "cookielawinfo-checkbox-necessary",
        "unique55": "GDPR Cookie Consent plugin",
        "unique56": "Necessary",
        "unique57": "11 meses",
        "unique58": "www.ctneat.com",
        "unique59": "cookielawinfo-checkbox-functional",
        "unique60": "GDPR Cookie Consent plugin",
        "unique61": "Functional",
        "unique62": "11 meses",
        "unique63": "4. ¿POR QUÉ NECESITAMOS SU CONSENTIMIENTO EXPLÍCITO PARA UTILIZARLAS?",
        "unique64": "Sentencia de la Gran Sala del Tribunal de Justicia de la Unión Europea, de 1 de octubre de 2019 y Guía sobre el uso de las cookies de la AEPD, julio de 2020.",
        "unique65": "La sentencia de la Gran Sala del Tribunal de Justicia de la Unión Europea, del día 1 de octubre de 2019, debe interpretarse en el sentido de que el consentimiento no se presta de manera válida cuando el almacenamiento de información a través de cookies, o el acceso a la información ya almacenada en el equipo del usuario de un sitio web de Internet, se autoriza mediante una casilla marcada por defecto, que el usuario debe desmarcar en caso de que desee rechazar su uso. Así, la autorización debe basarse en un consentimiento explícito mediante un Checkbox de aceptación explícita, una casilla de rechazo por parte del usuario y otra de más información de las cookies expuestas.",
        "unique66": "Esta interpretación, además, no puede variar en función de si la información almacenada o consultada en el terminal del usuario de un sitio web consta o no de datos de carácter personal.",
        "unique67": "La Gran Sala del Tribunal de Justicia de la Unión Europea también dispone en esta sentencia que la información que el proveedor de servicios debe facilitar al usuario de un sitio web de Internet debe incluir el tiempo en que las cookies permanecerán activas y la posibilidad de que terceros puedan acceder a la información que estas almacenan. Este requisito lo establece la versión modificada por la Directiva 2009/136, que modifica el artículo 5, apartado 3, de la Directiva 2002/58/UE, y por la Guía de la AEPD, de julio de 2020.",
        "unique68": "En relación a la posibilidad de que el usuario pueda rechazar el uso de las cookies, debe tenerse en cuenta que si las rechaza o bien borra las cookies de navegación por la Web, no podremos mantener sus preferencias, algunas características de las páginas no estarán operativas, no podremos ofrecerle servicios personalizados y cada vez que navegue por nuestro sitio web le pediremos de nuevo su autorización para el uso de las cookies.",
        "unique69": "Si aún así decide modificar la configuración de su acceso a la página web, debe saber que es posible eliminar las cookies o impedir que se registre esta información en su equipo en cualquier momento mediante la modificación de los parámetros de configuración de su navegador:",
        "unique70": "Configuración de cookies de Internet Explorer.",
        "unique71": "Configuración de cookies de Firefox.",
        "unique72": "Configuración de cookies de Google Chrome.",
        "unique73": "Configuración de cookies de Safari.",
        "unique74": "Estos navegadores están sujetos a actualizaciones o modificaciones, por lo que no podemos garantizar que se ajusten completamente a la versión de su navegador. También puede ser que utilice otro navegador no contemplado en estos enlaces como Konqueror, Arora, Flock, etc. Para evitar estos desajustes, puede acceder directamente desde las opciones de su navegador que generalmente se encuentra en el menú de Opciones, en la sección de 'Privacidad'. (Por favor, consulte la ayuda de su navegador para obtener más información.)",
        "unique75": "5. ¿DÓNDE PUEDO OBTENER MÁS INFORMACIÓN?",
        "unique76": "Puede encontrar más información sobre cookies en:",
        "Gracias1":"GRACIAS POR CONTACTAR CON NOSOTROS",
        "Gracias2":"En breve nos pondremos en contacto.",
        "Gracias3":"Volver",
        "AvisoCookies":"CTNEAT utiliza “COOKIES” para garantizar el correcto funcionamiento de nuestro portal web, mejorando la seguridad, para obtener una eficacia y personalización superiores y para recoger datos estadísticos. Al hacer clic en 'Aceptar', estás de acuerdo con el uso de cookies.",
        "AceptarCookies":"Aceptar",
        "RechazarCookies":"Rechazar",
        "MasInfoCookies":"Más información",
        "TitBlogTrace":"CTNEAT | Blog: Trazabilidad en la industria electrónica",
        "TitBlog": "CTNEAT | Blog: Las últimas noticias del sector",
        "TitCont": "CTNEAT | Contáctanos",
        "TitEmpr": "CTNEAT | Empresa",
        "TitInd": "CTNEAT | Soluciones para la industria 4.0.",
        "TitProd":"CTNEAT | Productos: Almacenamiento Inteligente, Trazabilidad y Agilidad",
        "TitThank": "CTNEAT | Gracias"
    },
    "ca": {
        "TextosInicio":["Innovant per al futur", "Desenvolupament personalitzat", "Aplicacions IoT", "Adaptació per a la indústria 4.0", "Optimització de processos",
        "Optimització de recursos", "Reducció d'errors"],
        "Menu-INICIO": "INICI",
        "Menu-EMPRESA": "EMPRESA",
        "Menu-PRODUCTOS": "PRODUCTES",
        "Menu-CONTACTO": "CONTACTE",
        "Menu-BLOG": "BLOC",
        "MenuFoot-INICIO": "Inici",
        "MenuFoot-EMPRESA": "Empresa",
        "MenuFoot-PRODUCTOS": "Productes",
        "MenuFoot-CONTACTO": "Contacte",
        "MenuFoot-BLOG": "Bloc",
        "MenuFoot-PoliticaCookies":"Política de cookies",
        "MenuFoot-Derechos":"© 2022 CTNEAT. Tots els drets reservats. Disseny de Duplex Creativity creació de CTNEAT.",
        "Ind40": "Indústria 4.0",
        "Ind40_1": "Desenvolupament, gestió i implementació d'aplicacions hiperconnectades i sistemes ciberfísics (CPS) aplicats a la indústria.",
        "Ind40_2": "Optimització dels nivells de qualitat, reducció dels temps de producció i reducció de costos.",
        "IoT": "IoT",
        "IoT_1": "Aplicació de les tecnologies IoT a les necessitats de la indústria. Adquisició automàtica de dades per a l'optimització de processos, creació de registres i validació contínua.",
        "DesPers": "Desenvolupament personalitzat",
        "DesPers_1": "Estudi de les necessitats del client per al desenvolupament i implementació d'aplicacions personalitzades per a tot tipus de dispositius.",
        "BigData": "Big Data",
        "BigData_1": "Creació i gestió de bases de dades de qualsevol mida i complexitat. Creació de models personalitzats per a un seguiment i control òptims i eficients.",
        "h1CTSten": "Solució d'emmagatzematge per a pantalles de serigrafia SMD",
        "h2CTSten": "CTSten | Traçabilitat, agilitat i control de l'envelliment",
        "CTSTEN_Desc_1": "Armari intel·ligent per emmagatzemar pantalles de serigrafia SMD amb marc auto-tensador. Té una capacitat per a 90 pantalles amb la possibilitat d'ampliar-la amb 5 mòduls de 90 pantalles cadascun i control de fins a 50 pantalles externes.",
        "CTSTEN_Desc_2": "Doble LED RGB, a dalt i a baix per a un centrado perfecte de la pantalla, el color és programable per indicar la vida útil de la pantalla.",
        "CTSTEN_Desc_3": "Generació de l'historial d'esdeveniments i usuaris. Mitjançant aquest control es pot saber quines pantalles porten més temps sense ús i la seva vida útil.",
        "CTSTEN_Desc_4": "Visualització de les pantalles antigues per optimitzar l'espai, verificació d'espais buits o plens.",
        "CTSTEN_Desc_5": "Els models actuals són: CTsten2323M, CTsten2329M, Ctsten2929M i CTsten2929MF, aquest últim amb marc fix i només 18 pantalles.",
        "PODUCTOS_Carac_1": "Perifèrics",
        "PODUCTOS_Carac_2": "Software",
        "CTSTEN_Carac_2": "Pantalla tàctil de 7”.",
        "CTSTEN_Carac_3": "Còpia de seguretat local a través de pen drive.",
        "CTSTEN_Carac_4": "Lector de codis de barres, datamàtrix, QR, per identificar les pantalles.",
        "CTSTEN_Carac_5": "Inclou teclat sense fil amb touchpad per facilitar l'entrada de dades.",
        "CTSTEN_Carac_6": "Suport per llapis i teclat.",
        "CTSTEN_Carac_7": "Connexió WIFI i Ethernet per comunicar-se amb l'ERP.",
        "CTSTEN_Carac_8": "Capacitat d'extreure una pantalla a partir de la informació d'una fabricació enviada per xarxa.",
        "CTSTEN_Carac_9": "Les pantalles es poden donar d'alta al mateix armari o enviar-li un fitxer per xarxa.",
        "CTSTEN_Carac_10": "Assignació automàtica o manual del lloc de la pantalla.",
        "CTSTEN_Carac_11": "El programari es pot personalitzar per afegir funcions específiques.",
        "CTSTEN_Tec_1": "Quatre models:",
        "CTSTEN_Tec_2": "Per a 90 pantalles amb LCD de 7”",
        "CTSTEN_Tec_3": "Per a 18 pantalles amb marc rígid",
        "CTSTEN_Tec_4": "COTA",
        "CTSTEN_Tec_5": "VALOR",
        "CTSTEN_Picto_1": "Indicació lluminosa de posició i vida útil",
        "CTSTEN_Picto_2": "No perdis el temps buscant pantalles",
        "CTSTEN_Picto_3": "Històric de pantalles",
        "CTSTEN_Picto_4": "Fins a 6 magatzems de diferents mides amb un sol control",
        "CTSTEN_Picto_5": "Connectivitat amb el teu ERP",
        "CTSTEN_MiniDesc_1": "Armari intel·ligent de fins a 90 pantalles connectable a l'ERP. Permet identificar, desar i extreure pantalles de manera més eficient i segura. Control de traçabilitat i envelliment de les pantalles.",
        "h1Prod": "Software de control de producció",
        "h2Prod": "CTProd | Controla cada pas en la teva cadena de producció",
        "CTPROD_MiniDesc_1": "Sistema de control de producció i seguiment de les fases de desenvolupament del producte. Permet tenir una trazabilitat total arribant fins al nivell de component i una qualitat garantida a la cadena de producció.",
        "CTPROD_Desc_1": "CTPROD és un ERP que permet un control senzill dels processos de producció i operaris, a més, és un sistema modular i adaptable a les necessitats de cada client.",
        "CTPROD_Desc_2": "Creació de producte final amb control per fase, temps, operaris i costos.",
        "CTPROD_Desc_3": "Informació en temps real de l'estat de les ordres de fabricació. Temps emprat, restant i unitats fetes en cada fase de l'ordre de treball.",
        "CTPROD_Desc_4": "Trazabilitat per enllaçar la producció amb la matèria primera.",
        "CTPROD_Desc_5": "Test de validació per al control de qualitat.",
        "CTPROD_Desc_6": "Control de la jornada laboral dels empleats per complir amb la normativa laboral.",
        "CTPROD_Desc_7": "La interacció amb els operaris mitjançant mòduls tàctils repartits en cada estació de treball.",
        "CTPROD_Cararc_1": "Pantalla tàctil de 7”.",
        "CTPROD_Cararc_2": "Lector de codi de barres.",
        "CTPROD_Cararc_3": "Mòduls amb monitors de 24”. (Opcional)",
        "CTPROD_Cararc_4": "Software modular i adaptable.",
        "CTPROD_Cararc_5": "Connexió WIFI i Ethernet per comunicar-se amb l'ERP.",
        "CTPROD_Cararc_6": "Trazabilitat de la producció. Temps, materials i costos.",
        "CTPROD_Cararc_7": "Control de l'estat de les fabricacions.",
        "CTPROD_Cararc_8": "Control de la jornada dels operaris.",
        "CTPROD_Cararc_9": "Control de qualitat.",
        "CTPROD_Cararc_10": "El software personalitzable per afegir funcions específiques.",
        "CTPROD_Picto_1": "Indicació lluminosa de posició i vida útil.",
        "CTPROD_Picto_2": "No perdis el temps buscant pantalles.",
        "CTPROD_Picto_3": "Històric de pantalles.",
        "CTPROD_Picto_4": "Fins a 6 magatzems de diferents mides amb un sol control.",
        "CTPROD_Picto_5": "Connectivitat amb el teu ERP.",
        "h1Incoming":"Estació de recepció de material",
        "h2Incoming": "CTIncoming | Evita errors y genera traçabilitat",
        "CTIncoming_MiniDesc_1": "Estació automatitzada d'entrada de materials. Optimitza el procés de recepció de materials mitjançant visió artificial.",
        "CTIncoming_Desc_1": "CTIncoming és una taula ergonòmica d'entrada de material equipada amb una càmera d'alta resolució de 20 Megapíxels i un PC on s'executa el software de gestió de recepcions.",
        "CTIncoming_Desc_2": "El material entrant es escaneja i les dades es cotegen automàticament amb la base de dades de l'ERP de l'empresa. L'estació crea una identificació única en el sistema per a cada material i fa que cada component sigui únic per facilitar una trazabilitat completa.",
        "CTIncoming_Desc_3": "El sistema reconeix tots els codis de barres (1D i 2D) gràcies a una càmera d'alta resolució. Permet generar regles de reconeixement per a la classificació dels codis de barres llegits i desar els valors juntament amb el codi únic creat.",
        "CTIncoming_Desc_4": "Admet la connexió a sistemes de tercers com ERPs i armaris mitjançant l'API integrada.",
        "CTIncoming_Desc_5": "Disposa d'una aplicació mòbil per poder recepcionar paquets a distància. Aquesta extensió està especialment dissenyada per a aquells paquets voluminosos o pesats que no es puguin pujar a l'escàner.",
        "CTIncoming_Carac_1": "PC i7, 8GB RAM 256 GB.",
        "CTIncoming_Carac_2": "Monitor tàctil de FullHD 23.8” i suport braç articulat.",
        "CTIncoming_Carac_3": "Teclat i ratolí.",
        "CTIncoming_Carac_4": "Taula ergonòmica (1500 x 750 x 670-1100)",
        "CTIncoming_Carac_5": "Càmera 20 MP i lent.",
        "CTIncoming_Carac_6": "Impressora d'etiquetes.",
        "CTIncoming_Carac_7": "Lectura de codis 1D i 2D.",
        "CTIncoming_Carac_8": "Reconeixement i classificació de text llegit als codis.",
        "CTIncoming_Carac_9": "Entrada de material a la base de dades i a l'ERP.",
        "CTIncoming_Carac_10": "Comunicació amb ERPs i armaris intel·ligents.",
        "CTIncoming_Carac_11": "Comunicació amb ERPs i armaris intel·ligents.",
        "CTIncoming_Picto_1": "Recepció de materials sense errors",
        "CTIncoming_Picto_2": "Reducció del temps de recepció",
        "CTIncoming_Picto_3": "Trazabilitat total del material per UID",
        "CTIncoming_Picto_4": "Connectivitat amb armaris intel·ligents",
        "CTIncoming_Picto_5": "Connectivitat amb l'ERP",
        "CTIncoming_Picto_6": "Extensió mòbil",
        "PRODUC_DT_1": "AMPLADA",
        "PRODUC_DT_2": "PROFUNDITAT",
        "PRODUC_DT_3": "ALÇÀRIA",
        "h1Shelf":"Magatzem automatic SMD",
        "h2Shelf":"CTShelf | Emmagatzematge i recuperació eficients",
        "CTShelf_Picto_1": "Indicadors lumínics i sonors",
        "CTShelf_Picto_2": "Sensors de posició",
        "CTShelf_Picto_3": "Integració amb CTShelf Manager",
        "CTShelf_Picto_4": "Lliure personalització",
        "CTShelf_Picto_5": "Ordre, Rapidesa i senzillesa",
        "CTShelf_MiniDesc_1": "Armari d'emmagatzematge de Bobines SMD amb capacitat per emmagatzemar fins a 1200 bobines.",
        "CTShelf_Desc_1": "CTShelf és un armari caòtic de Bobines SMD, una solució altament adaptable per a la gestió eficient de components electrònics en entorns industrials.",
        "CTShelf_Desc_2": "Aquest producte es pot configurar de manera flexible per acomodar tota la gamma de mides de bobines SMD, la qual cosa ofereix una personalització completa segons les necessitats de l'usuari. Amb una capacitat d'emmagatzematge de fins a 1200 bobines SMD, aquest armari és una opció ideal per a entorns de producció d'alt volum.",
        "CTShelf_Desc_3": "Cada posició d'emmagatzematge està equipada amb un indicador lluminós que utilitza tecnologia LED RGB i un sensor de posició per proporcionar una identificació visual clara de l'estat de les bobines.",
        "CTShelf_Desc_4": "A més, CTShelf inclou un indicador sonor per proporcionar informació addicional sobre l'estat de les bobines.",
        "CTShelf_Desc_5": "Disposa de connexió amb el software CTShelf Manager per al control de la inserció i extracció de components de forma més precisa i ràpida.",
        "CTShelf_Carac_1": "CTShelf Manager (enllaçat amb la pàgina de CTShelf Manager del lloc web).",
        "CTShelfManag_MiniDesc_1": "Software de gestió de producció i magatzem dissenyat per millorar l'eficiència i maximitzar el control sobre els recursos i processos. Compta amb suport per a Windows i dispositius Android.",
        "CTShelfManag_Desc_1": "CTShelf Manager és un software de gestió de producció dissenyat amb la finalitat d'incrementar l'eficiència i proporcionar un major control sobre els recursos i processos involucrats en la producció. Aquest versàtil software és compatible tant amb sistemes Windows com amb dispositius Android.",
        "CTShelfManag_Desc_2": "El software ofereix una àmplia funcionalitat en la gestió d'ordres de treball, permetent als usuaris dissenyar, gestionar i fer seguiment de les ordres de producció de manera eficaç. Això inclou l'assignació de recursos, l'establiment de terminis i el manteniment d'un control precís en tot el procés. A més, el software facilita l'inici i tancament de les ordres de treball, proporcionant la flexibilitat necessària per fer ajustos en temps real i adaptar-se a les necessitats canviant de producció.",
        "CTShelfManag_Desc_3": "La gestió d'estoc és una altra àrea on CTShelf Manager brillant. Proporciona un seguiment detallat dels materials, el que ajuda a mantenir un equilibri adequat en els nivells d'inventari. CTShelf Manager també ofereix eines per a la generació de informes per tenir un resum de les ordres de treball juntament amb els contenidors que han format part d'aquesta.",
        "CTShelfManag_Desc_4": "A més de la seva versatilitat en Windows, CTShelf Manager compta amb una aplicació Android que simplifica encara més el seu ús en la gestió del magatzem. Aquesta extensió mòbil permet als usuaris accedir al software des de dispositius Android, proporcionant flexibilitat en la gestió de magatzems.",
        "CTShelfManag_Desc_5": "Per a una major integració i eficiència, el software es pot sincronitzar amb sistemes ERP, evitant duplicacions de dades i garantint una comunicació fluïda entre diferents parts del negoci.",
        "CTShelfManag_Desc_6": "Finalment, CTShelf Manager ofereix l'opció d'integrar-se amb els magatzems intel·ligents CTShelf i CTShelf mini, la qual cosa complementa i millora encara més la gestió del magatzem aprofitant la tecnologia avançada d'aquests sistemes.",
        "CTShelfManag_Carac_1": "Comunicació amb ERPs.",
        "CTShelfManag_Carac_2": "Comunicació i gestió d'armaris externs i extensions CTShelf i CTShelf Mini.",
        "CTShelfManag_Carac_3": "Comunicació amb CTIncoming.",
        "CTShelfManag_Carac_4": "Trazabilitat d'ordres de treball i magatzem.",
        "CTShelfManag_Carac_5": "Creació, planificació, control i manipulació d'ordres de treball.",
        "CTShelfManag_Carac_6": "Aplicació Android.",
        "CTShelfManag_Carac_7": "Generació de informes.",
        "CTShelfManag_Picto_1": "Gestió i control de la producció i magatzems",
        "CTShelfManag_Picto_2": "Extensió mòbil",
        "CTShelfManag_Picto_3": "Generació d'informes",
        "CTShelfManag_Picto_4": "Connectivitat amb armaris intel·ligents",
        "CTShelfManag_Picto_5": "Connectivitat amb l'ERP",
        "h1ShelfManager":"MRP y software de gestió de magatzem",
        "h2ShelfManager":"CTShelf Manager | Gestió eficient de la producció i del magatzem.",
        "PRODUC_TABS_1": "Descripció",
        "PRODUC_TABS_2": "Característiques",
        "PRODUC_TABS_3": "Dades Tècniques",
        "PRODUC_TABS_4": "Descàrregues" ,
        "PRODUC_Descarga_1": "Fullet",
        "CONTACTO_1": "PARLEM",
        "CONTACTO_2": "Vols informació sobre algun dels nostres productes? Tens algun projecte que vulguis desenvolupar amb nosaltres? Vols adaptar-te a la indústria 4.0? Parla amb nosaltres.",
        "CONTACTO_3": "LES NOSTRES DADES",
        "CONTACTO_4": "CONTACTE",
        "CONTACTO_5": "Nom",
        "CONTACTO_6": "Empresa",
        "CONTACTO_7": "Telèfon",
        "CONTACTO_8": "Missatge",
        "CONTACTO_9": "Accepto l'avís legal i la política de privacitat",
        "CONTACTO_10": "Enviar",
        "PRODUCTOS_1": "La tecnologia al servei de l'usuari",
        "PRODUCTOS_2": "A CTNEAT fabriquem equips i aplicacions de programari que permeten millorar els temps de producció, comunicar sistemes amb noves tecnologies IoT i controlar la producció i traçabilitat.",
        "PRODUCTOS_3": "Armari intel·ligent de fins a 90 pantalles connectable a l'ERP.",
        "PRODUCTOS_4": "Sistema de control de producció i seguiment de les fases de desenvolupament del producte.",
        "PRODUCTOS_5": "Estació d'entrada de materials.",
        "PRODUCTOS_6": "Armari d'Emmagatzematge de Bobines SMD.",
        "PRODUCTOS_7": "Programari de gestió de producció i magatzem.",
        "PRODUCTOS_8": "Més Informació",
        "Empresa_1": "EMPRESA",
        "Empresa_2": "CTneat som una enginyeria electrònica amb més de 30 anys d'experiència enfocada al desenvolupament de solucions per a la indústria 4.0.",
        "Empresa_3": "Els nostres productes estan pensats per adaptar-se a les necessitats generals de la indústria, amb la capacitat de ser personalitzats per cada client, tant a nivell de programari com de maquinari.",
        "Empresa_4": "Treballem estretament amb col·laboradors que ens faciliten la fabricació dels productes dissenyats pels nostres enginyers i lliurar-los totalment verificats.",
        "Empresa_5": "Des de l'any 2001 estem ubicats a Sentmenat amb una bona comunicació amb Barcelona.",
        "Empresa_6": "Creixement",
        "Empresa_7": "El nostre caràcter emprenedor ens ha permès aconseguir una expansió que segueix creixent dia a dia, apostant per les noves tecnologies i el talent jove.",
        "Empresa_8": "Comunitat",
        "Empresa_9": "La nostra filosofia es basa en el desenvolupament de solucions internacionals, però mantenint l'essència que ens ha fet créixer. Una essència marcada per l'aposta en el mercat local.",
        "Empresa_10": "Compromís",
        "Empresa_11": "Motivats pel nostre esforç de millora contínua de productes i processos, hem creat un compromís amb l'optimització dels recursos mediambientals.",
        "Empresa_12": "Què fem?",
        "Empresa_13": "Desenvolupem projectes d'innovació industrial amb diverses tecnologies:",
        "Empresa_14": "Aplicacions IoT, BLE i WiFi.",
        "Empresa_15": "Visió artificial.",
        "Empresa_16": "Connectivitat.",
        "Empresa_17": "Fabriquem equips i aplicacions de programari que permeten:",
        "Empresa_18": "Millorar els temps de producció.",
        "Empresa_19": "Comunicar sistemes amb noves tecnologies IoT.",
        "Empresa_20": "Control de la producció i traçabilitat.",
        "BLOG_1":"ACTUALITAT",
        "titulo-politica-cookies": "POLÍTICA DE COOKIES",
        "seccion-1-titulo": "1. QUÈ SÓN LES COOKIES ?",
        "seccion-1-parrafo-1": "Les cookies són petits arxius de dades que es reben en el terminal des del lloc web visitat i s’usen per registrar certes interaccions de la navegació en un lloc web emmagatzemant dades que podran ser actualitzats i recuperats. Aquests arxius s’emmagatzemen a l’ordinador de l’usuari i conté dades anònims que no són perjudicials per al seu equip. S’utilitzen per recordar les preferències de l’usuari, com l’idioma seleccionat, dades d’accés o personalització de la pàgina.",
        "seccion-1-parrafo-2": "Les cookies també poden ser utilitzades per registrar informació anònima sobre com un visitant utilitza un lloc web. Per exemple, des de quina pàgina ha accedit, o si ha utilitzat un “banner” publicitari per arribar-hi.",
        "seccion-2-titulo": "2. PER QUÈ UTILITZEM COOKIES ?",
        "seccion-2-parrafo-1": "CTNEAT utilitza cookies estrictament necessàries i essencials perquè utilitzin els nostres llocs web i els hi permetin moure’s lliurement, utilitzar àrees segures, opcions personalitzades, etc. A més, CTNEAT utilitza cookies que recullen dades relatives a l’anàlisi d’ús del web. Aquestes s’utilitzen per ajudar a millorar el servei al client, mesurant l’ús i el rendiment de la pàgina, per optimitzar-la i personalitzar-la.",
        "seccion-2-parrafo-2": "Els nostres llocs web també poden tenir enllaços de xarxes socials (com Facebook o Twitter). CTNEAT no controla les cookies utilitzades per aquests webs externs. Per a més informació sobre les cookies de les xarxes socials o altres Webs alienes, aconsellem revisar les seves pròpies polítiques de cookies.",
        "seccion-2-parrafo-3": "Aquest lloc web utilitza “cookies” de la següent manera:",
        "seccion-2-lista-item-1": "Cookie de Google ANALYTICS: Google ANALYTICS és un servei gratuït que ofereix Google Inc. i que recull informació sobre les pàgines de la web consultada, a quina hora, quin navegador Web, etc. Posteriorment, aquesta informació s’envia als servidors de Google Inc. als Estats Units.",
        "seccion-2-lista-item-2": "Cookies d’aplicació “Add-this”: Add-this és un servei gratuït que li permet compartir pàgines d’aquest lloc web per diferents mitjans (correu electrònic, xarxes socials, etc.). Add-this compila informació estadística de la vostra navegació per proporcionar publicitat adaptada als seus hàbits de navegació en aquest lloc web o en qualsevol altre lloc.",
        "seccion-3-titulo1": "Segons la seva finalitat:",
        "seccion-3-titulo2": "Cookies tècniques: Les cookies tècniques són aquelles imprescindibles i estrictament necessàries per al correcte funcionament d’un portal web i la utilització de les diferents opcions i serveis que ofereix. Por exemple, les que serveixen per al manteniment de la sessió, la gestió del temps de resposta, rendiment o validació d’opcions, utilitzar elements de seguretat, compartir contingut amb xarxes socials, etc.",
        "seccion-3-titulo3": "Cookies de personalització: Aquestes cookies permeten a l’usuari especificar o personalitzar algunes característiques de les opcions generals de la pàgina web, per exemple, definir l’idioma, configuració regional o tipus de navegador.",
        "seccion-3-titulo4": "Cookies analítiques: Les cookies analítiques són les utilitzades pel nostre portal web, per elaborar perfils de navegació i poder conèixer les preferències dels usuaris del mateix per tal de millorar l’oferta de productes i serveis. Per exemple, mitjançant una galeta analítica es controlarien les àrees geogràfiques de major interès d’un usuari, quin és el producte de més acceptació, etc.",
        "seccion-3-titulo5": "Cookies publicitàries / de publicitat: Les cookies publicitàries permeten la gestió dels espais publicitaris en base a criteris concrets. Per exemple la freqüència d’accés, el contingut editat, etc. Les cookies de publicitat permeten a través de la gestió de la publicitat emmagatzemar informació del comportament a través de l’observació d’hàbits, estudiant els accessos i formant un perfil de preferències de l’usuari, per oferir publicitat relacionada amb els interessos del seu perfil.",
        "seccion-3-titulo6": "Segons termini:",
        "seccion-3-titulo7": "Cookies de sessió: Les cookies de sessió són aquelles que duren el temps que l’usuari està navegant per la pàgina web i s’esborren al terme.",
        "seccion-3-titulo8": "Cookies persistents: Aquestes cookies queden emmagatzemades en el terminal de l’usuari, per un temps més llarg, facilitant així el control de les preferències triades sense haver de repetir certs paràmetres cada vegada que es visiti el lloc web.",
        "seccion-3-parrafo-1": "Aquestes cookies han de complir amb l’RGPD-UE-2016/679, l’LOPD-3/2018, de Garantia dels Drets Digitals, i la normativa de l’LSSI-CE.",
        "seccion-4-titulo1": "COOKIES PRÒPIES EN ÚS:",
        "seccion-4-titulo2": "S’han permès les següents cookies en visitar aquesta pàgina, entre d’altres:",
        "unique1": "DOMINI",
        "unique2": "COOKIE",
        "unique3": "EMPRESA",
        "unique4": "FUNCIÓ",
        "unique5": "CADUCITAT",
        "unique6": "www.ctneat.com",
        "unique7": "_utma",
        "unique8": "Google Analytics",
        "unique9": "Analítica",
        "unique10": "2 anys",
        "unique11": "_utmb",
        "unique12": "Analítica",
        "unique13": "30 minuts",
        "unique14": "_utmc",
        "unique15": "Analítica",
        "unique16": "Sessió",
        "unique17": "_utmt",
        "unique18": "Analítica",
        "unique19": "10 minuts",
        "unique20": "_utmv",
        "unique21": "Analítica",
        "unique22": "Sessió",
        "unique23": "_utmz",
        "unique24": "Analítica",
        "unique25": "6 mesos",
        "unique26": "COOKIES EXTERNES EN ÚS:",
        "unique27": "Algunes de les cookies en ús:",
        "unique28": "DOMINI",
        "unique29": "COOKIE",
        "unique30": "EMPRESA",
        "unique31": "FUNCIÓ",
        "unique32": "CADUCITAT",
        "unique33": "www.ctneat.com",
        "unique34": "cookielawinfo-checkbox-performance",
        "unique35": "GDPR Cookie Consent plugin",
        "unique36": "Performance",
        "unique37": "11 mesos",
        "unique38": "www.ctneat.com",
        "unique39": "cookielawinfo-checkbox-analytics",
        "unique40": "GDPR Cookie Consent plugin",
        "unique41": "Analytics",
        "unique42": "11 mesos",
        "unique43": "www.ctneat.com",
        "unique44": "cookielawinfo-checkbox-others",
        "unique45": "GDPR Cookie Consent plugin",
        "unique46": "Other",
        "unique47": "11 mesos",
        "unique48": "www.ctneat.com",
        "unique49": "viewed_cookie_policy",
        "unique50": "GDPR Cookie Consent plugin",
        "unique51": "&nbsp;",
        "unique52": "11 mesos",
        "unique53": "www.ctneat.com",
        "unique54": "cookielawinfo-checkbox-necessary",
        "unique55": "GDPR Cookie Consent plugin",
        "unique56": "Necessary",
        "unique57": "11 mesos",
        "unique58": "www.ctneat.com",
        "unique59": "cookielawinfo-checkbox-functional",
        "unique60": "GDPR Cookie Consent plugin",
        "unique61": "Functional",
        "unique62": "11 mesos",
        "unique63": "4. PER QUÉ NECESSITEM EL SEU CONSENTIMENT EXPLÍCIT PER A UTILIZAR-LES?",
        "unique64": "Sentència de la Gran Sala del Tribunal de Justícia de la Unió Europea, d’1 d’octubre de 2019 i Guia sobre l’ús de les cookies de l’AEPD, juliol de 2020.",
        "unique65": "La sentència de la Gran Sala del Tribunal de Justícia de la Unió Europea, del dia 1 d’octubre de 2019, s’ha d’interpretar en el sentit de què el consentiment no es presta de manera vàlida quan l’emmagatzematge d’informació a través de cookies, o l’accés a la informació ja emmagatzemada a l’equip de l’usuari d’un lloc web d’internet, s’autoritza mitjançant una casella marcada per defecte, que l’usuari ha de desmarcar en cas que vulgui rebutjar-ne l’ús. Així, l’autorització s’ha de fonamentar en un consentiment explícit mitjançant un Checkbox d’acceptació explícita, un check de rebuig per part de l’usuari i un altre de més informació de les cookies exposades.",
        "unique66": "Aquesta interpretació, a més, no pot variar en funció de si la informació emmagatzemada o consultada al terminal de l’usuari d’un lloc web consta o no de dades de caràcter personal.",
        "unique67": "La Gran Sala del Tribunal de Justícia de la Unió Europea també disposa en aquesta sentència que la informació que el proveïdor de serveis ha de facilitar a l’usuari d’un lloc web d’internet ha d’incloure el temps en què les cookies romandran actives i la possibilitat de què tercers puguin accedir a la informació que aquestes emmagatzemen. Aquest requisit l’estableix la versió modificada per la Directiva 2009/136, que modifica l’article 5, apartat 3, de la Directiva 2002/58/UE, i per la Guia de l’AEPD, de juliol de 2020.",
        "unique68": "En relació a la possibilitat de què l’usuari pugui rebutjar l’ús de les cookies, cal tenir en compte que si les rebutja o bé esborra les cookies de navegació per la Web, no podrem mantenir les seves preferències, algunes característiques de les pàgines no estaran operatives, no podrem oferir-li serveis personalitzats i cada vegada que navegui pel nostre web li haurem de sol·licitar de nou la seva autorització per a l’ús de les cookies.",
        "unique69": "Si tot i així, decideix modificar la configuració del seu accés a la pàgina web, ha de saber que és possible eliminar les cookies o impedir que es registri aquesta informació en el seu equip en qualsevol moment mitjançant la modificació dels paràmetres de configuració del seu navegador:",
        "unique70": "Configuració de cookies de Internet Explorer.",
        "unique71": "Configuració de cookies de Firefox.",
        "unique72": "Configuració de cookies de Google Chrome.",
        "unique73": "Configuració de cookies de Safari.",
        "unique74": "Aquests navegadors estan sotmesos a actualitzacions o modificacions, pel que no podem garantir que s’ajustin completament a la versió del seu navegador. També pot ser que utilitzi un altre navegador no contemplat en aquests enllaços com ara Konqueror, Arora, Flock, etc. Per evitar aquests desajustos, pot accedir directament des de les opcions del seu navegador que es troba generalment al menú d’Opcions, a la secció de “Privacitat.” (Si us plau, consulteu l’ajuda del seu navegador per a més informació.)",
        "unique75": "5. ON PUC OBTENIR MÉS INFORMACIÓ ?",
        "unique76": "Pots trobar més informació sobre cookies a:",
        "Gracias1":"GRÀCIES PER CONTACTAR AMB NOSALTRES",
        "Gracias2":"En breu ens posarem en contacte.",
        "Gracias3":"Tornar",
        "AvisoCookies":"CTNEAT utilitza “COOKIES” per garantir el correcte funcionament del nostre portal web, millorant la seguretat, per obtenir una eficàcia i personalització superiors i per recollir dades estadístiques. En fer clic a 'Acceptar', estàs d'acord amb el ús de cookies.",
        "AceptarCookies":"Acceptar",
        "RechazarCookies":"Refusar",
        "MasInfoCookies":"Més informació",
        "TitBlogTrace": "CTNEAT | Blog: Traçabilitat en la indústria electrònica",
        "TitBlog": "CTNEAT | Blog: Les últimes notícies del sector",
        "TitCont": "CTNEAT | Contacta amb nosaltres",
        "TitEmpr": "CTNEAT | Empresa",
        "TitInd": "CTNEAT | Solucions per a la indústria 4.0.",
        "TitProd":"CTNEAT | Productes: Emmagatzematge Intel·ligent, Traçabilitat i Agilitat",
        "TitThank": "CTNEAT | Gràcies"
    },
    "en": {
        "TextosInicio":["Innovating for the future", "Custom development", "IoT applications", "Adaptation for industry 4.0", "Process optimization",
        "Resource optimization", "Error reduction"],
        "Menu-INICIO": "HOME",
        "Menu-EMPRESA": "COMPANY",
        "Menu-PRODUCTOS": "PRODUCTS",
        "Menu-CONTACTO": "CONTACT",
        "Menu-BLOG": "BLOG",
        "MenuFoot-INICIO": "Home",
        "MenuFoot-EMPRESA": "Company",
        "MenuFoot-PRODUCTOS": "Products",
        "MenuFoot-CONTACTO": "Contact",
        "MenuFoot-BLOG": "Blog",
        "MenuFoot-PoliticaCookies":"Cookies policy",
        "MenuFoot-Derechos":"© 2022 CTNEAT. All rights reserved. Duplex Creativity design, creation of CTNEAT.",
        "Ind40": "Industry 4.0",
        "Ind40_1": "Development, management, and implementation of hyperconnected applications and cyber-physical systems (CPS) applied to the industry.",
        "Ind40_2": "Optimization of quality levels, reduction of production times, and cost reduction.",
        "IoT": "IoT",
        "IoT_1": "Application of IoT technologies to industry needs. Automatic data acquisition for process optimization, record creation, and continuous validation.",
        "DesPers": "Custom Development",
        "DesPers_1": "Analysis of customer needs for the development and implementation of custom applications for all types of devices.",
        "BigData": "Big Data",
        "h1CTSten": "Stencil storage solution SMD",
        "h2CTSten": "CTSten | Traceability, agility and aging control",
        "BigData_1": "Creation and management of databases of any size and complexity. Custom model creation for optimal and efficient monitoring and control.",
        "CTSTEN_Desc_1": "Smart cabinet for storing SMD stencils with self-tensioning frames. It has a capacity for 90 stencils with the possibility of expanding it with 5 modules of 90 stencils each and control of up to 50 external stencils.",
        "CTSTEN_Desc_2": "Double RGB LED, top and bottom for perfect stencil alignment; the color is programmable to indicate the stencil's lifespan.",
        "CTSTEN_Desc_3": "Generation of event and user history. Through this control, it is possible to identify stencils with the longest idle time and their lifespan.",
        "CTSTEN_Desc_4": "Display of old stencils to optimize space, checking for empty or occupied spaces.",
        "CTSTEN_Desc_5": "Current models include: CTsten2323M, CTsten2329M, Ctsten2929M, and CTsten2929MF, the latter with a fixed frame and only 18 stencils.",
        "PODUCTOS_Carac_1": "Peripherals",
        "PODUCTOS_Carac_2": "Software",
        "CTSTEN_Carac_2": "7-inch touch screen.",
        "CTSTEN_Carac_3": "Local backup on a pen drive.",
        "CTSTEN_Carac_4": "Barcode, datamatrix, QR code reader to identify stencil.",
        "CTSTEN_Carac_5": "Includes wireless keyboard with touchpad for easy data input.",
        "CTSTEN_Carac_6": "Support for stylus and keyboard.",
        "CTSTEN_Carac_7": "WIFI and Ethernet connection to communicate with the ERP.",
        "CTSTEN_Carac_8": "Ability to extract a stencil based on information sent over the network.",
        "CTSTEN_Carac_9": "Stencils can be registered in the same cabinet or sent via a network file.",
        "CTSTEN_Carac_10": "Automatic or manual assignment of stencil location.",
        "CTSTEN_Carac_11": "Customizable software to add specific functions.",
        "CTSTEN_Tec_1": "Four models:",
        "CTSTEN_Tec_2": "For 90 stencils with 7” LCD",
        "CTSTEN_Tec_3": "For 18 stencils with rigid frame",
        "CTSTEN_Tec_4": "DIMENSION",
        "CTSTEN_Tec_5": "VALUE",
        "CTSTEN_Picto_1": "Light indication of position and lifespan",
        "CTSTEN_Picto_2": "Don't waste time searching for stencils",
        "CTSTEN_Picto_3": "Stencil history",
        "CTSTEN_Picto_4": "Up to 6 warehouses of different sizes with a single control",
        "CTSTEN_Picto_5": "Connectivity with your ERP",
        "CTSTEN_MiniDesc_1": "Smart cabinet for up to 90 stencil connectable to the ERP. Allows for more efficient and secure identification, storage, and extraction of stencils. Traceability and aging control for the stencils.",
        "h1Prod": "Production control software",
        "h2Prod": "CTProd | Control every step of your production chain",
        "CTPROD_MiniDesc_1": "Production control system and tracking of product development phases. Allows for total traceability down to the component level and guaranteed quality in the production chain.",
        "CTPROD_Desc_1": "CTPROD is an ERP that enables easy control of production processes and operators; moreover, it is a modular system adaptable to each client's needs.",
        "CTPROD_Desc_2": "Creation of final product with phase, time, operator, and cost control.",
        "CTPROD_Desc_3": "Real-time information on the status of manufacturing orders. Time used, remaining time, and units made in each phase of the work order.",
        "CTPROD_Desc_4": "Traceability to link production with raw materials.",
        "CTPROD_Desc_5": "Validation tests for quality control.",
        "CTPROD_Desc_6": "Control of employees' working hours to comply with labor regulations.",
        "CTPROD_Desc_7": "Interaction with operators through touchscreen modules distributed at each workstation.",
        "CTPROD_Cararc_1": "7-inch touchscreen.",
        "CTPROD_Cararc_2": "Barcode reader.",
        "CTPROD_Cararc_3": "Modules with 24-inch monitors. (Optional)",
        "CTPROD_Cararc_4": "Modular and adaptable software.",
        "CTPROD_Cararc_5": "WIFI and Ethernet connectivity to communicate with the ERP.",
        "CTPROD_Cararc_6": "Production traceability. Time, materials, and costs.",
        "CTPROD_Cararc_7": "Control of manufacturing states.",
        "CTPROD_Cararc_8": "Control of operators' work hours.",
        "CTPROD_Cararc_9": "Quality control.",
        "CTPROD_Cararc_10": "Customizable software to add specific functions.",
        "CTPROD_Picto_1": "Light indication of position and lifespan.",
        "CTPROD_Picto_2": "Don't waste time searching for stencils.",
        "CTPROD_Picto_3": "Stencil history.",
        "CTPROD_Picto_4": "Up to 6 warehouses of different sizes with a single control.",
        "CTPROD_Picto_5": "Connectivity with your ERP.",
        "h1Incoming":"Incoming material station",
        "h2Incoming": "CTIncoming | Avoid errors and generate traceability",
        "CTIncoming_MiniDesc_1": "Automated material entry station. Optimizes the material reception process using artificial vision.",
        "CTIncoming_Desc_1": "CTIncoming is an ergonomic material entry table equipped with a high-resolution 20 Megapixel camera and a PC running reception management software.",
        "CTIncoming_Desc_2": "Incoming material is scanned, and the data is automatically matched with the company's ERP database. The station creates a unique identification in the system for each material, making each component unique to facilitate complete traceability.",
        "CTIncoming_Desc_3": "The system recognizes all barcode types (1D and 2D) thanks to a high-resolution camera. It allows generating recognition rules for the classification of scanned barcodes and saves the values along with the created unique code.",
        "CTIncoming_Desc_4": "Supports connection to third-party systems like ERPs and cabinets through the integrated API.",
        "CTIncoming_Desc_5": "It has a mobile application to remotely receive packages. This extension is specially designed for bulky or heavy packages that cannot be scanned.",
        "CTIncoming_Carac_1": "PC i7, 8GB RAM 256 GB.",
        "CTIncoming_Carac_2": "23.8” FullHD touchscreen monitor and articulated arm support.",
        "CTIncoming_Carac_3": "Keyboard and mouse.",
        "CTIncoming_Carac_4": "Ergonomic table (1500 x 750 x 670-1100)",
        "CTIncoming_Carac_5": "20 MP camera and lens.",
        "CTIncoming_Carac_6": "Label printer.",
        "CTIncoming_Carac_7": "1D and 2D barcode reading.",
        "CTIncoming_Carac_8": "Recognition and classification of text read to codes.",
        "CTIncoming_Carac_9": "Material entry into the database and ERP.",
        "CTIncoming_Carac_10": "Communication with ERPs and smart cabinets.",
        "CTIncoming_Carac_11": "Communication with ERPs and smart cabinets.",
        "CTIncoming_Picto_1": "Error-free material reception",
        "CTIncoming_Picto_2": "Reduction in reception time",
        "CTIncoming_Picto_3": "Total material traceability by UID",
        "CTIncoming_Picto_4": "Connectivity with smart cabinets",
        "CTIncoming_Picto_5": "Connectivity with the ERP",
        "CTIncoming_Picto_6": "Mobile extension",
        "PRODUC_DT_1": "WIDTH",
        "PRODUC_DT_2": "DEPTH",
        "PRODUC_DT_3": "HEIGHT",
        "h1Shelf":"Automated SMD warehouse",
        "h2Shelf":"CTShelf | Efficient storage and retrieval",
        "CTShelf_Picto_1": "Light and sound indicators",
        "CTShelf_Picto_2": "Position sensors",
        "CTShelf_Picto_3": "Integration with CTShelf Manager",
        "CTShelf_Picto_4": "Customizable",
        "CTShelf_Picto_5": "Order, Speed, and Simplicity",
        "CTShelf_MiniDesc_1": "Storage cabinet for SMD Reels with the capacity to store up to 1200 reels.",
        "CTShelf_Desc_1": "CTShelf is a chaotic SMD Reel cabinet, a highly adaptable solution for efficient management of electronic components in industrial environments.",
        "CTShelf_Desc_2": "This product can be flexibly configured to accommodate the entire range of SMD reel sizes, providing complete customization according to user needs. With a storage capacity of up to 1200 SMD reels, this cabinet is an ideal choice for high-volume production environments.",
        "CTShelf_Desc_3": "Each storage position is equipped with a light indicator that uses RGB LED technology and a position sensor to provide a clear visual identification of the status of the reels.",
        "CTShelf_Desc_4": "Additionally, CTShelf includes a sound indicator to provide additional information about the status of the reels.",
        "CTShelf_Desc_5": "It has connectivity with CTShelf Manager software for more precise and faster control of component insertion and extraction.",
        "CTShelf_Carac_1": "CTShelf Manager (hyperlinked to the CTShelf Manager page on the website).",
        "CTShelfManag_MiniDesc_1": "Production and warehouse management software designed to improve efficiency and maximize control over resources and processes. It has support for both Windows and Android devices.",
        "CTShelfManag_Desc_1": "CTShelf Manager is production management software designed to increase efficiency and provide greater control over the resources and processes involved in production. This versatile software is compatible with both Windows systems and Android devices.",
        "CTShelfManag_Desc_2": "The software offers extensive functionality in managing work orders, allowing users to design, manage, and track production orders effectively. This includes resource allocation, setting deadlines, and maintaining precise control throughout the process. Additionally, the software facilitates the initiation and closure of work orders, providing the flexibility to make real-time adjustments and adapt to changing production needs.",
        "CTShelfManag_Desc_3": "Stock management is another area where CTShelf Manager shines. It provides detailed tracking of materials, helping maintain a proper balance in inventory levels. CTShelf Manager also offers tools for generating reports to have an overview of work orders along with the containers that have been part of it.",
        "CTShelfManag_Desc_4": "In addition to its versatility on Windows, CTShelf Manager has an Android application that further simplifies its use in warehouse management. This mobile extension allows users to access the software from Android devices, providing flexibility in warehouse management.",
        "CTShelfManag_Desc_5": "For greater integration and efficiency, the software can be synchronized with ERP systems, avoiding data duplications and ensuring smooth communication between different parts of the business.",
        "CTShelfManag_Desc_6": "Finally, CTShelf Manager offers the option to integrate with CTShelf and CTShelf Mini smart cabinets, further complementing and enhancing warehouse management by leveraging the advanced technology of these systems.",
        "CTShelfManag_Carac_1": "Communication with ERPs.",
        "CTShelfManag_Carac_2": "Communication and management of external cabinets and CTShelf and CTShelf Mini extensions.",
        "CTShelfManag_Carac_3": "Communication with CTIncoming.",
        "CTShelfManag_Carac_4": "Traceability of work orders and warehouse.",
        "CTShelfManag_Carac_5": "Creation, planning, control, and manipulation of work orders.",
        "CTShelfManag_Carac_6": "Android application.",
        "CTShelfManag_Carac_7": "Report generation.",
        "CTShelfManag_Picto_1": "Production and warehouse management",
        "CTShelfManag_Picto_2": "Mobile extension",
        "CTShelfManag_Picto_3": "Report generation",
        "CTShelfManag_Picto_4": "Connectivity with smart cabinets",
        "CTShelfManag_Picto_5": "Connectivity with ERP",
        "h1ShelfManager":"MRP and warehouse manager software",
        "h2ShelfManager":"CTShelf Manager | Efficient production and warehouse management",
        "PRODUC_TABS_1": "Description",
        "PRODUC_TABS_2": "Features",
        "PRODUC_TABS_3": "Technical Data",
        "PRODUC_TABS_4": "Downloads"  ,
        "PRODUC_Descarga_1": "Brochure",
        "CONTACTO_1": "LET'S TALK",
        "CONTACTO_2": "Want information about any of our products? Have a project you want to develop with us? Looking to adapt to Industry 4.0? Talk to us.",
        "CONTACTO_3": "OUR DETAILS",
        "CONTACTO_4": "CONTACT",
        "CONTACTO_5": "Name",
        "CONTACTO_6": "Company",
        "CONTACTO_7": "Phone",
        "CONTACTO_8": "Message",
        "CONTACTO_9": "I accept the legal notice and privacy policy",
        "CONTACTO_10": "Send",
        "PRODUCTOS_1": "Technology at the Service of the User",
        "PRODUCTOS_2": "At CTNEAT, we manufacture equipment and software applications that improve production times, connect systems with new IoT technologies, and control production and traceability.",
        "PRODUCTOS_3": "Smart cabinet for up to 90 stencils connectable to ERP.",
        "PRODUCTOS_4": "Production control system and product development phase tracking.",
        "PRODUCTOS_5": "Material entry station.",
        "PRODUCTOS_6": "SMD Coil Storage Cabinet.",
        "PRODUCTOS_7": "Production and warehouse management software.",
        "PRODUCTOS_8": "More Info",
        "Empresa_1": "COMPANY",
        "Empresa_2": "CTneat is an electronic engineering firm with over 30 years of experience focused on developing solutions for Industry 4.0.",
        "Empresa_3": "Our products are designed to adapt to the general needs of the industry, with the ability to be customized for each client, both in terms of software and hardware.",
        "Empresa_4": "We work closely with partners who facilitate the manufacturing of products designed by our engineers and deliver them fully verified.",
        "Empresa_5": "Since 2001, we have been located in Sentmenat with good connectivity to Barcelona.",
        "Empresa_6": "Growth",
        "Empresa_7": "Our entrepreneurial spirit has allowed us to achieve ongoing expansion, embracing new technologies and young talent.",
        "Empresa_8": "Community",
        "Empresa_9": "Our philosophy is based on developing international solutions while maintaining the essence that has driven our local market growth.",
        "Empresa_10": "Commitment",
        "Empresa_11": "Driven by our commitment to continuous improvement of products and processes, we are dedicated to optimizing environmental resources.",
        "Empresa_12": "What Do We Do?",
        "Empresa_13": "We develop industrial innovation projects using various technologies:",
        "Empresa_14": "IoT, BLE, and WiFi applications.",
        "Empresa_15": "Computer vision.",
        "Empresa_16": "Connectivity.",
        "Empresa_17": "We manufacture equipment and software applications that enable:",
        "Empresa_18": "Improving production times.",
        "Empresa_19": "Connecting systems with new IoT technologies.",
        "Empresa_20": "Production control and traceability.",
        "BLOG_1":"NEWS",
        "titulo-politica-cookies": "COOKIE POLICY",
        "seccion-1-titulo": "1. WHAT ARE COOKIES?",
        "seccion-1-parrafo-1": "Cookies are small data files that are received on your device from the website you visit and are used to record certain interactions of your browsing by storing data that can be updated and retrieved. These files are stored on your computer and contain anonymous data that is not harmful to your device. They are used to remember user preferences, such as the selected language, login data, or page customization.",
        "seccion-1-parrafo-2": "Cookies can also be used to record anonymous information about how a visitor uses a website. For example, from which page they accessed it or if they used an advertising banner to get there.",
        "seccion-2-titulo": "2. WHY DO WE USE COOKIES?",
        "seccion-2-parrafo-1": "CTNEAT uses strictly necessary cookies that allow users to use our websites and move freely, use secure areas, customize options, etc. In addition, CTNEAT uses cookies that collect data related to web usage analysis. These are used to help improve customer service by measuring page usage and performance, optimizing and personalizing it.",
        "seccion-2-parrafo-2": "Our websites may also have links to social networks (such as Facebook or Twitter). CTNEAT does not control the cookies used by these external websites. For more information about cookies from social networks or other external websites, we recommend reviewing their own cookie policies.",
        "seccion-2-parrafo-3": "This website uses 'cookies' as follows:",
        "seccion-2-lista-item-1": "Google Analytics Cookie: Google Analytics is a free service offered by Google Inc. that collects information about the pages of the visited website, the time, the web browser used, etc. Subsequently, this information is sent to Google Inc.'s servers in the United States.",
        "seccion-2-lista-item-2": "Add-this Application Cookies: Add-this is a free service that allows you to share pages of this website through various means (email, social networks, etc.). Add-this compiles statistical information about your browsing to provide advertising tailored to your browsing habits on this website or any other site.",
        "seccion-3-titulo1": "Based on their purpose:",
        "seccion-3-titulo2": "Technical Cookies: Technical cookies are essential and strictly necessary for the proper functioning of a website and the use of the different options and services it offers. For example, those used for session maintenance, response time management, performance, or validation of options, using security elements, sharing content with social networks, etc.",
        "seccion-3-titulo3": "Customization Cookies: These cookies allow the user to specify or customize some features of the general options of the website, such as setting the language, regional configuration, or browser type.",
        "seccion-3-titulo4": "Analytical Cookies: Analytical cookies are used by our website to create browsing profiles and know the preferences of its users to improve the offer of products and services. For example, using an analytical cookie, the geographic areas of greatest interest to a user would be controlled, which is the most accepted product, etc.",
        "seccion-3-titulo5": "Advertising Cookies: Advertising cookies allow the management of advertising spaces based on specific criteria, such as access frequency, edited content, etc. Advertising cookies allow through the management of advertising to store information on behavior through the observation of habits, studying access and forming a profile of user preferences to offer advertising related to the interests of your profile.",
        "seccion-3-titulo6": "Based on their term:",
        "seccion-3-titulo7": "Session Cookies: Session cookies are those that last as long as the user is browsing the website and are deleted when finished.",
        "seccion-3-titulo8": "Persistent Cookies: These cookies are stored on the user's device for a longer time, making it easier to control the chosen preferences without having to repeat certain parameters every time you visit the website.",
        "seccion-3-parrafo-1": "These cookies must comply with the GDPR-UE-2016/679, the LOPD-3/2018, on the Guarantee of Digital Rights, and the LSSI-CE regulations.",
        "seccion-4-titulo1": "COOKIES IN USE:",
        "seccion-4-titulo2": "The following cookies have been allowed when visiting this page, among others:",
        "unique1": "DOMAIN",
        "unique2": "COOKIE",
        "unique3": "COMPANY",
        "unique4": "FUNCTION",
        "unique5": "EXPIRATION",
        "unique6": "www.ctneat.com",
        "unique7": "_utma",
        "unique8": "Google Analytics",
        "unique9": "Analytics",
        "unique10": "2 years",
        "unique11": "_utmb",
        "unique12": "Analytics",
        "unique13": "30 minutes",
        "unique14": "_utmc",
        "unique15": "Analytics",
        "unique16": "Session",
        "unique17": "_utmt",
        "unique18": "Analytics",
        "unique19": "10 minutes",
        "unique20": "_utmv",
        "unique21": "Analytics",
        "unique22": "Session",
        "unique23": "_utmz",
        "unique24": "Analytics",
        "unique25": "6 months",
        "unique26": "EXTERNAL COOKIES IN USE:",
        "unique27": "Some of the cookies in use:",
        "unique28": "DOMAIN",
        "unique29": "COOKIE",
        "unique30": "COMPANY",
        "unique31": "FUNCTION",
        "unique32": "EXPIRATION",
        "unique33": "www.ctneat.com",
        "unique34": "cookielawinfo-checkbox-performance",
        "unique35": "GDPR Cookie Consent plugin",
        "unique36": "Performance",
        "unique37": "11 months",
        "unique38": "www.ctneat.com",
        "unique39": "cookielawinfo-checkbox-analytics",
        "unique40": "GDPR Cookie Consent plugin",
        "unique41": "Analytics",
        "unique42": "11 months",
        "unique43": "www.ctneat.com",
        "unique44": "cookielawinfo-checkbox-others",
        "unique45": "GDPR Cookie Consent plugin",
        "unique46": "Other",
        "unique47": "11 months",
        "unique48": "www.ctneat.com",
        "unique49": "viewed_cookie_policy",
        "unique50": "GDPR Cookie Consent plugin",
        "unique51": "&nbsp;",
        "unique52": "11 months",
        "unique53": "www.ctneat.com",
        "unique54": "cookielawinfo-checkbox-necessary",
        "unique55": "GDPR Cookie Consent plugin",
        "unique56": "Necessary",
        "unique57": "11 months",
        "unique58": "www.ctneat.com",
        "unique59": "cookielawinfo-checkbox-functional",
        "unique60": "GDPR Cookie Consent plugin",
        "unique61": "Functional",
        "unique62": "11 months",
        "unique63": "4. WHY DO WE NEED YOUR EXPLICIT CONSENT TO USE THEM?",
        "unique64": "Judgment of the Grand Chamber of the Court of Justice of the European Union, dated October 1, 2019, and Guide on the use of cookies by the AEPD, July 2020.",
        "unique65": "The judgment of the Grand Chamber of the Court of Justice of the European Union, dated October 1, 2019, must be interpreted to mean that consent is not validly given when the storage of information through cookies or access to information already stored on the user's Internet website is authorized by default, which the user must uncheck if they wish to reject its use. Thus, authorization must be based on explicit consent through an explicit acceptance Checkbox, a user rejection checkbox, and another for more information about the exposed cookies.",
        "unique66": "Furthermore, this interpretation cannot vary depending on whether the information stored or accessed on the user's terminal of a website contains personal data or not.",
        "unique67": "The Grand Chamber of the Court of Justice of the European Union also stipulates in this judgment that the information the service provider must provide to the user of an Internet website must include the period during which cookies will remain active and the possibility that third parties may access the information they store. This requirement is established by the amended version of Directive 2009/136, which amends Article 5(3) of Directive 2002/58/EC, and by the AEPD Guide of July 2020.",
        "unique68": "Regarding the possibility for the user to reject the use of cookies, it should be noted that if they reject or delete navigation cookies on the Web, we will not be able to maintain their preferences, some features of the pages will not be operational, and we will need to request their authorization for cookie use every time they browse our website.",
        "unique69": "If, nonetheless, they decide to modify the settings for their access to the website, they should know that it is possible to delete cookies or prevent this information from being recorded on their device at any time by modifying the settings parameters of their browser:",
        "unique70": "Internet Explorer cookie settings.",
        "unique71": "Firefox cookie settings.",
        "unique72": "Google Chrome cookie settings.",
        "unique73": "Safari cookie settings.",
        "unique74": "These browsers are subject to updates or modifications, so we cannot guarantee that they will fully match your browser version. It is also possible that you are using another browser not covered by these links, such as Konqueror, Arora, Flock, etc. To avoid these mismatches, you can directly access the settings of your browser, usually found in the Options menu, under the 'Privacy' section. (Please consult your browser's help for more information.)",
        "unique75": "5. WHERE CAN I GET MORE INFORMATION?",
        "unique76": "You can find more information about cookies at:",
        "Gracias1":"THANK YOU FOR CONTACTING US",
        "Gracias2":"We will contact you shortly.",
        "Gracias3":"Return",
        "AvisoCookies":"CTNEAT uses 'COOKIES' to guarantee the correct functioning of our web portal, improving security, to obtain superior efficiency and personalization and to collect statistical data. By clicking 'Accept', you agree to the use of cookies.",
        "AceptarCookies":"Accept",
        "RechazarCookies":"Reject",
        "MasInfoCookies":"More information",
        "TitBlogTrace": "CTNEAT | Blog: Traceability in the electronic industry",
        "TitBlog": "CTNEAT | Blog: The latest sector news",
        "TitCont": "CTNEAT | Contact us",
        "TitEmpr": "CTNEAT | Company",
        "TitInd": "CTNEAT | Solutions for the industry 4.0.",
        "TitProd": "CTNEAT | Products: Smart Storage, Traceability, and Agility",
        "TitThank": "CTNEAT | Thank you"
    }
}
